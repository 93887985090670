@import '_constants.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_fonts.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/abstracts/_mixins.sass'
@import '_variables.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/components/__components.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/layout/__layout.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/pages/__pages.sass'
@import '../../node_modules/srs.sharedcomponents/lib/esm/sass/main.sass'

// .NewBox
// 	background: $primary-color
// 	border-top-left-radius: 10px
// 	border-top-right-radius: 10px
// 	font-size: 18px
// 	color: White
// 	font-weight: bold
// 	font-family: Arial black,helvetica,sans-serif
// 	text-align: center
// 	font-style: italic
// .NewBox-sec
// 	font-family: "Helvetica",verdana,arial,sans-serif
// 	border: 2px solid $primary-color
// 	border-bottom-left-radius: 10px
// 	border-bottom-right-radius: 10px
// .NewBox-img
// 	float: left
// 	width: 90px
// 	margin-left: 0px
// 	font-family: "Helvetica",verdana,arial,sans-serif
// .NewBox-text
// 	float: right
// 	width: 67px
// 	font-family: "Helvetica",verdana,arial,sans-serif
// 	font-size: 15px
// 	padding: 2px
// 	font-style: italic
// 	font-weight: bold


#lblCartItemsCount
	top: -12px

#lblCartItemsCount > #CartItemCount
	color: #fff

#headerMobileCartIcon > #lblCartItemsCount
	left: 3px
	top: -6px
#headerMobileCartIcon > #lblCartItemsCount > #CartItemCount 
	color: initial

#headerLogoutButton
	color: #fff

#headerLoginLink	
	color: #fff

.welcome div#mainZoneImage img
	cursor: default

.NewBox
	background: $primary-color
	border-top-left-radius: 10px
	border-top-right-radius: 10px
	font-size: 18px
	color: White
	font-weight: bold
	font-family: Arial black,helvetica,sans-serif
	text-align: center
	height: 58px
	height: 58px
	// width: 191px
	font-style: italic

.NewBox-sec
	min-height: 240px
	font-size: 8pt
	min-height: 230px
	border: 2px solid $primary-color
	border-bottom-left-radius: 10px
	border-bottom-right-radius: 10px
	font-family: "Helvetica",verdana,arial,sans-serif
	a
		color: $blue-bootstrap-color

.NewBox-img
	float: left
	width: 100%
	margin-left: 0px
	font-family: "Helvetica",verdana,arial,sans-serif

.NewBox-text
	float: right
	width: 67px
	font-family: "Helvetica",verdana,arial,sans-serif
	font-size: 15px
	padding: 2px
	font-style: italic
	font-weight: bold

.welcome div#welcomeZoneImage, .welcome div#additionalZone1Image, .welcome div#additionalZone2Image, .welcome div#additionalZone3Image, .welcome div#additionalZone4Image
	text-align: center
	width: 20%
.welcome div#additionalZone1Image img, div#additionalZone2Image img,div#additionalZone3Image img,div#additionalZone4Image img
	width: 100% !important

div#additionalZone3Image img
	height: 140px !important

.header__row .logo
	padding-top: 0 !important
	padding-bottom: 1.5rem
img#headerLogoImg
	width: 100%

.header-text

	color: $primary-color
	position: absolute
	left: 27%
	font-size: 25px
	font-family: Verdana, Arial, Sans-Serif
	padding-left: 30px
	line-height: 80px
	font-style: normal
	width: 100%
	top: -40px

body
	overflow-x: hidden

nav.header__top-nav.navbar
	background: $primary-color !important
	color: $light-color
.top-log-menu a, #myAccountDropdownMenu
	color: $light-color

nav.header__top-nav.navbar
	padding: 0.1rem 1.5rem 0.1rem 0.1rem

@media screen and (min-width: 600px) and (max-width: 991px)
	nav.header__top-nav.navbar, .top-log-menu a, #myAccountDropdownMenu
		color: $dark-color
	.header-text
		font-size: 16px
	.welcome div#welcomeZoneImage, .welcome div#additionalZone1Image, .welcome div#additionalZone2Image, .welcome div#additionalZone3Image, .welcome div#additionalZone4Image
		padding-top: 1rem
		text-align: center
		width: 33%
	.NewBox-sec
		font-size: 8pt
		min-height: 240px

@media screen and (min-width: 400px) and (max-width:600px)
	nav.header__top-nav.navbar, .top-log-menu a, #myAccountDropdownMenu
		color: $dark-color
	.welcome div#welcomeZoneImage, .welcome div#additionalZone1Image, .welcome div#additionalZone2Image, .welcome div#additionalZone3Image, .welcome div#additionalZone4Image
		width: 48%
		padding-top: 1rem
		text-align: center
	.NewBox-sec
		font-size: 8pt
		min-height: 268px
	.header-text
		font-size: 16px
		transform: translate(-12%, 0px)
@media screen and (min-width: 0px) and (max-width: 400px)
	nav.header__top-nav.navbar, .top-log-menu a, #myAccountDropdownMenu
		color: $dark-color
	.welcome div#welcomeZoneImage, .welcome div#additionalZone1Image, .welcome div#additionalZone2Image, .welcome div#additionalZone3Image, .welcome div#additionalZone4Image
		width: 99%
		padding-top: 1rem
		text-align: center
	.NewBox-sec
		font-size: 8pt
		min-height: 268px

	div#additionalZone3Image img
		height: unset !important

@media screen and (min-width: 500px) and (max-width: 766px)
	.header-text
		font-size: 15px

@media screen and (min-width: 320px) and (max-width: 499px)
	.header-text
		font-size: 0.6rem

@media screen and (min-width: 1400px)
	.NewBox-sec
		height: 340px
